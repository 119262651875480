import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import { device } from "../../../assets/styles/size";

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
`;

export const ImgCol = styled.div`
	flex: 0 0 100%;
	max-width: 100%;
	order: 1;
	position: relative;
	@media ${device.tablet} {
		order: ${(props) => (props.position === "left" ? 0 : 1)};
		flex: 0 0 50%;
		max-width: 50%;
	}
	@media ${device.laptopS} {
		flex: 0 0 60%;
		max-width: 60%;
	}
	&:before {
		@media ${device.tablet} {
			content: "";
			display: block;
			padding-bottom: 60%;
		}
	}
	figure {
		margin: 0;
		@media ${device.tablet} {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			@media ${device.tablet} {
				height: 80%;
			}
			@media ${device.tabletL} {
				height: 100%;
			}
		}
	}
`;
export const InfoCol = styled.div`
	background-color: ${colors.black};
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	order: 0;
	flex: 0 0 100%;
	max-width: 100%;

	@media ${device.tablet} {
		order: ${(props) => (props.position === "left" ? 1 : 0)};
		flex: 0 0 50%;
		max-width: 50%;
	}
	@media ${device.laptopS} {
		flex: 0 0 40%;
		max-width: 40%;
	}
`;

export const InfoColInner = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding: 40px;
	color: ${colors.white};
	font-size: 16px;
	line-height: 1.4;
	h3 {
		font-family: "AudiType-Extended";
		font-weight: 400;
		font-size: 18px;
		line-height: 1.2;
		margin: 0 0 6px -1px;
	}
	h2 {
		font-family: "AudiType-Extended";
		font-weight: 700;
		font-size: 20px;
		line-height: 1.2;
		margin: 0 0 20px -2px;
	}
	ul {
		list-style: none;
		margin: 0 0 20px;
		padding: 0;
		li {
			margin-top: 12px;
			&:first-of-type {
				margin: 0;
			}
		}
	}
	@media ${device.tablet} {
		font-size: 18px;
		padding: 50px;
		h3 {
			font-size: 24px;
		}
		h2 {
			font-size: 36px;
		}
		ul {
			margin: 0 0 40px;
			li {
				margin-top: 20px;
				&:first-of-type {
					margin: 0;
				}
			}
		}
	}
	@media ${device.laptop} {
	}
	@media ${device.laptopL} {
		font-size: 20px;
		h3 {
			font-size: 30px;
		}
		h2 {
			font-size: 50px;
		}
	}
`;

export const PriceWrapper = styled.div`
	margin-bottom: 20px;
	p {
		font-family: "AudiType-Extended";
		font-weight: 700;
		font-size: 36px;
		line-height: 1.2;
		margin: 0 0 0 -5px;
	}
	@media ${device.laptopL} {
		p {
			font-size: 50px;
		}
	}
	@media ${device.laptopL} {
		margin-bottom: 30px;
		p {
			font-size: 78px;
		}
	}
`;
