import React, { useEffect } from "react";
import Slider from "react-slick";
import "../../../assets/css/slick/slick.min.css";
import { Wrapper } from "./styled";
import SliderArrow from "../SliderArrow";
import SliderDots from "../SliderDots";
import PromocionModeloItem from "../PromocionModeloItem";
import API from "../../../classes/api";

const PromocionModeloSlider = ({ position, sliderItems }) => {
	const sliderSettings = {
		dots: true,
		arrows: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		prevArrow: <SliderArrow color="light" position="prev" />,
		nextArrow: <SliderArrow color="light" position="next" />,
		appendDots: (dots) => <SliderDots dots={dots} />,
	};

	return (
		<Wrapper>
			{sliderItems.length > 0 && (
				<Slider {...sliderSettings}>
					{sliderItems.map((item, index) => {
						return (
							<PromocionModeloItem
								key={index}
								position={position}
								image={item.image}
								preTitle={item.preTitle}
								text={item.text}
								title={item.title}
								price={item.price}
								priceNote={item.priceNote}
								featuresList={item.featuresList}
								buttons={item.buttons}
							/>
						);
					})}
				</Slider>
			)}
		</Wrapper>
	);
};

export default PromocionModeloSlider;
