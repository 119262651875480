import React from "react";
import { Wrapper, ImgCol, InfoCol, InfoColInner, PriceWrapper } from "./styled";
import { CustomButton } from "../../../assets/styles/commonStyles";
import { ButtonsWrapper } from "../PromocionItem/styled";

const PromocionModeloItem = (props) => {
	const {
		position,
		image,
		preTitle,
		title,
		text,
		price,
		priceNote,
		featuresList,
		buttons,
	} = props;
	return (
		<Wrapper>
			<ImgCol position={position}>
				<figure>
					<img src={image} alt={title} />
				</figure>
			</ImgCol>
			<InfoCol position={position}>
				<InfoColInner>
					{preTitle && <h3>{preTitle}</h3>}
					{title && <h2>{title}</h2>}
					{!title && <h2>&nbsp;</h2>}
					{text && <h2>{text}</h2>}
					{!text && <h2>&nbsp;</h2>}
					{(price || priceNote) && (
						<PriceWrapper>
							{price && <p>{price}</p>}
							{priceNote && <span>{priceNote}</span>}
						</PriceWrapper>
					)}

					{featuresList && (
						<ul>
							{featuresList.map((item, index) => (
								<li key={index}>&gt; {item.value}</li>
							))}
						</ul>
					)}

					{buttons && (
						<ButtonsWrapper>
							{buttons.map((button, index) => (
								<div key={index}>
									<CustomButton
										layout={index === 0 ? "light" : "light3"}
										href={button.url}
										target={button.nuevaVentana ? "_blank" : "_top"}
									>
										{button.text}
									</CustomButton>
								</div>
							))}
						</ButtonsWrapper>
					)}
				</InfoColInner>
			</InfoCol>
		</Wrapper>
	);
};

export default PromocionModeloItem;
